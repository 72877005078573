var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loggedInUser)?_c('div',[(this.allSessions == undefined || this.allSessions.length == 0)?_c('aad-loader'):_c('div',[(_vm.cmeOnly)?_c('router-link',{staticClass:"btn btn-primary-solid",attrs:{"to":{ name: 'Login', query: { redirect: '/', name: 'Logout' } }},nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}}},[_vm._v(" Logout ")]):_vm._e(),_c('aad-title',[_vm._v("CME Credit Claims "),(_vm.cmeOnly)?_c('span',[_vm._v("- "+_vm._s(_vm.loggedInUser.name))]):_vm._e()]),(_vm.isLoggedInUserVerified)?_c('div',[_c('div',{staticClass:"alert alert-info mb-4",attrs:{"role":"alert"}},[_c('div',[_c('aad-icon',{staticClass:"warning-icon",attrs:{"name":"info-circle-filled"}}),_vm._v(" "+_vm._s(_vm.currentMeeting.Name)+" CME Information: "),(
              this.currentMeeting && this.currentMeeting.CMEInformationLink
            )?_c('a',{staticClass:"font-weight-bold reg-text-link-light-bg",attrs:{"target":"_blank","href":_vm.currentMeeting.CMEInformationLink},on:{"click":function($event){return _vm.logLinkClick(
                'cme_information_doc',
                _vm.currentMeeting.CMEInformationLink,
                'cme'
              )}}},[_vm._v(" Click Here ")]):_vm._e()],1)]),_c('div',{staticClass:"alert alert-primary mb-4",attrs:{"role":"alert"}},[_c('div',[_c('aad-icon',{staticClass:"warning-icon",attrs:{"name":"warning-circle-filled"}}),_vm._v(" Please also take a minute to fill out the "),_c('router-link',{staticClass:"reg-text-link-light-bg font-weight-bold",attrs:{"to":{ name: 'CMESurvey', params: { sessionCode: 'OVERALL' } }},nativeOn:{"click":function($event){return _vm.logLinkClick('overall_eval', 'CMESurvey', 'cme')},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.logLinkClick('overall_eval', 'CMESurvey', 'cme')}}},[_vm._v(" Overall Evaluation ")]),_vm._v(". ")],1)]),_c('div',{staticClass:"alert alert-primary mb-4",attrs:{"role":"alert"}},[_c('div',[_c('aad-icon',{staticClass:"warning-icon",attrs:{"name":"summary-circle-filled"}}),_vm._v(" To access a certificate of participation with a transcript of your credits for this meeting: "),_c('a',{staticClass:"reg-text-link-light-bg font-weight-bold",attrs:{"target":"_blank","href":("https://meetings.aad.org/Evaluations/Gateway/Certificate/" + _vm.getMeetingCode + "/" + (_vm.loggedInUser.registrationCode) + "/" + (_vm.loggedInUser.registrationHash))},on:{"click":function($event){return _vm.logLinkClick(
                'certificate_of_partic',
                ("https://meetings.aad.org/Evaluations/Gateway/Certificate/" + _vm.getMeetingCode + "/" + (_vm.loggedInUser.registrationCode) + "/" + (_vm.loggedInUser.registrationHash)),
                'cme'
              )}}},[_vm._v("Click Here")])],1)]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Use the search bar below to find the sessions that you attended. Click the claim button and fill out the form to submit for your credit. ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"form-control",attrs:{"id":"txtSearch","type":"text","placeholder":"Search Sessions","aria-label":"Search"},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}}),_c('div',{staticClass:"row"},[(this.currentMeeting)?_c('div',{staticClass:"col-md-6 order-md-2 p-2"},[(!_vm.isCmeSingleDay)?_c('h3',{staticClass:"p-2"},[_vm._v(" Claimed Credits ("+_vm._s(_vm.getNumberOfCreditsClaimed())+" / "+_vm._s(_vm.currentMeeting.CmeMaxLimit)+") ")]):_vm._e(),(_vm.allSessions && _vm.allSessions.length > 0)?_c('div',_vm._l((_vm.orderedCreditLimits),function(limit){return _c('div',{key:limit.Day,staticClass:"meeting-app-info-block"},[_c('div',{staticClass:"meeting-app-info-header row"},[(!_vm.isCmeSingleDay)?_c('h4',[_vm._v(" "+_vm._s(_vm.getLimitDate(limit))+" - ("+_vm._s(_vm.getNumberOfCreditsClaimed(_vm.getLimitDate(limit)))+" / "+_vm._s(limit.CreditAmount)+") ")]):_c('h4',[_vm._v(" Claimed Credits - ("+_vm._s(_vm.getNumberOfCreditsClaimed(_vm.getLimitDate(limit)))+" / "+_vm._s(limit.CreditAmount)+") ")])]),_c('div',{staticClass:"meeting-app-info-body p-1"},[(
                    _vm.cmeSessions &&
                    _vm.cmeSessions.length > 0 &&
                    _vm.getNumberOfCreditsClaimed(_vm.getLimitDate(limit)) > 0
                  )?_c('div',_vm._l((_vm.cmeSessions.filter(
                      function (session) { return _vm.isCreditClaimActiveBySessionCode(
                          session.SessionCode
                        ) && _vm.getSessionDate(session) == _vm.getLimitDate(limit); }
                    )),function(session){return _c('CME-session-card',{key:session.id,staticClass:"meeting-app-info-item",attrs:{"session":session}})}),1):_c('div',{staticClass:"p-2 font-weight-bold"},[_vm._v(" No claimed CME credits. ")])])])}),0):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-md-6 order-md-1 p-2"},[(_vm.allSessions && _vm.allSessions.length > 0)?_c('div',{staticClass:"meeting-app-info-block"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('h4',[_vm._v("All Sessions")])]),_c('div',{staticClass:"meeting-app-info-body p-1"},[(_vm.cmeSessions && _vm.cmeSessions.length > 0)?_c('div',_vm._l((_vm.cmeSessions),function(session){return _c('div',{key:session.id,staticClass:"meeting-app-info-item"},[_c('CME-session-card',{attrs:{"session":session}})],1)}),0):_c('div',[_vm._v("No Available Sessions")])])]):_vm._e()])])]):_c('div',[_c('p',[_vm._v(" You must be verified on site in order to claim CME credits. If you believe you are seeing this in error, please try refreshing your web browser. ")])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }